// import React from "react";
// import "../assets/styles/About.css";
// import sbs1 from "../assets/images/sbs1.png";
// import sbs2 from "../assets/images/sbs2.png";
// import sbs3 from "../assets/images/sbs3.png";
// import tick from "../assets/images/tick.png";
// import p1 from "../assets/images/p1.png";
// import p2 from "../assets/images/p2.png";
// import p3 from "../assets/images/p3.png";
// import p4 from "../assets/images/p4.png";
// import p5 from "../assets/images/p5.png";
// import { useNavigate, Link } from "react-router-dom";
// import {greatchange} from "great-change";
// const About = () => {
//   const nav = useNavigate();
//   return (
//     <div className="about-section">
//       <div className="about-black">ABOUT US</div>
//       <div
//         className="about-black-below"
//         style={{
//           margin: "auto",
//           textAlign: "center",
//           textTransform: "uppercase",
//         }}
//       >
//       <h3>Smart & Elegant Solutions</h3>
//       </div>
//       <div className="side-by-side">
//         <div className="side-by-side-left">
//           <img src={sbs1} alt="" />
//         </div>
//         <div className="side-by-side-right">
//           <h3>Our Mission</h3>
//           <p style={{ color: "rgba(0, 0, 0, 0.75)" }}>
//             We are committed to creating smart, elegant, and innovative solutions that enrich the lives of our customers.
//             Drawing inspiration from the ever-evolving landscape of technology, we have dedicated ourselves to pushing the limits of
//             what's possible, pioneering innovative solutions that make everyday living truly exceptional.
//             Our Smart and elegant solutions simplify your daily routines and provide the best entertainment,
//             comfort, and security in any home space, creating an ideal environment for building long-lasting memories.
//           </p>
//           {/* <p style={{ fontSize: "1rem" }}>Sharon Gunther</p>
//           <p style={{ color: "#AD9A9A", fontSize: "0.95rem" }}>Fresh Design</p> */}
//         </div>
//       </div>
//       <br />
//       <br />
//       <br />
//       <div className="side-by-side">
//         <div className="side-by-side-right">
//           <h3>Our Objectives</h3>
//           <div className="d-flex align-items-start my-3">
//             <div>
//               <img src={tick} alt="" height={25} />
//             </div>
//           <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
//                We develop intelligent, uncomplicated, and streamlined solutions for your household. 
//                We will thoroughly assess your needs and challenges and create designs to enhance your discovery experience.
//             </div>
//             <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
//                With over 15 years of experience in the field, our team rigorously tests all high-quality smart products on the market
//               to ensure that we deliver smart, connected, and elegant solutions. Our solutions work seamlessly,
//               allowing customers to easily operate and depend on them consistently.
//             </div>
//           </div>
//           <div className="d-flex align-items-start my-3">
//             <div>
//               <img src={tick} alt="" height={25} />
//             </div>
//             <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
//              We are customer-focused and committed to surpassing your goals. We listen to your needs, goals, and challenges and work closely with 
//              your family to achieve your dreams. Witnessing your vision come to life is our ultimate reward. 
//             </div>
//           </div>
//           </div>
//           </div>
//           <button className="wwd-btn" onClick={()=>nav("/consultation")}>REQUEST A QUOTE</button>
//       <br />
//       <br />
//       <br />
//       <div className="">
//         <p style={{ textAlign: "center", color: "#757575" }}></p>
//         <h3 style={{ textAlign: "center" }}>Brand Partners</h3>
//         <br />
//         <div className="parts">
//           <Link to={"https://www.denon.com/en-us/"} target="_blank"><div className="part-each">
//             <img src={p1} height={40} alt="" />
//           </div></Link>
//           <Link to={"https://www.fxl.com/"} target="_blank"><div className="part-each">
//             <img src={p2} height={40} alt="" />
//           </div></Link>
//           <Link to={"https://www.jvc.com/in/"} target="_blank"><div className="part-each">
//             <img src={p3} height={40} alt="" />
//           </div></Link>
//           <Link to={"https://international.kef.com/"} target="_blank"><div className="part-each">
//             <img src={p4} height={40} alt="" />
//           </div></Link>
//           <Link to={"https://www.klipsch.com/"} target="_blank"><div className="part-each">
//             <img src={p5} height={40} alt="" />
//           </div></Link>
//         </div>
//       </div>
//       <br />
//       <br />
//       <br />
//       <div className="about-signup">
//         <h3 style={{ textAlign: "center" }}>Sign up to receive product</h3>
//         <h3 style={{ textAlign: "center", marginTop: "-5px" }}>
//           update or discounts
//         </h3>
//         <br />
//         <div className="form-about-su">
//           <div className="flname">
//             <input
//               className="flname-text"
//               type="text"
//               placeholder="First Name"
//             />
//             <input
//               className="flname-text"
//               type="text"
//               placeholder="Last Name"
//             />
//           </div>
//           <input
//             className="flname-text my-4"
//             style={{ width: "100%" }}
//             type="email"
//             placeholder="Email Address"
//           />
//           <br />
//           <button className="btn-send-ab-su" style={{ width: "100%" }}>
//             Send
//           </button>
//         </div>
//         <br />
//         <br />
//         <br />
//       </div>
//     </div>
//   );
// };

// export default About;
import React from "react";
import "../assets/styles/About.css";
import sbs1 from "../assets/images/sbs1.png";
import sbs2 from "../assets/images/sbs2.png";
import sbs3 from "../assets/images/sbs3.png";
import tick from "../assets/images/tick.png";
import p1 from "../assets/images/p1.png";
import p2 from "../assets/images/p2.png";
import p3 from "../assets/images/p3.png";
import p4 from "../assets/images/p4.png";
import p5 from "../assets/images/p5.png";
import p6 from "../assets/images/image.png";
import p7 from "../assets/images/image-1.png";
import p8 from "../assets/images/image-2.png";
import p9 from "../assets/images/image-3.png";
import p10 from "../assets/images/image-4.png";
import p11 from "../assets/images/image-5.png";
import p12 from "../assets/images/image-6.png";
import p13 from "../assets/images/image-7.png";
import p14 from "../assets/images/image-8.png";
import p15 from "../assets/images/image-9.png";
import p16 from "../assets/images/image-10.png";
import p17 from "../assets/images/image-11.png";
import p18 from "../assets/images/image-12.png";
import p19 from "../assets/images/image-13.png";
import p20 from "../assets/images/image-14.png";
import p21 from "../assets/images/image-15.png";
import p22 from "../assets/images/image-16.png";
import p23 from "../assets/images/image-17.png";
import p24 from "../assets/images/image-18.png";
import p25 from "../assets/images/image-19.png";
import p26 from "../assets/images/image-20.png";
import p27 from "../assets/images/image-21.png";
import p28 from "../assets/images/image-22.png";
import p29 from "../assets/images/image-23.png";
import p30 from "../assets/images/image-24.png";
import p31 from "../assets/images/image-25.png";
import { useNavigate, Link } from "react-router-dom";

const About = () => {
  const logos = [
    {
      img: p1,
      link: "https://www.denon.com/en-us/",
    },
    {
      img: p2,
      link: "https://www.fxl.com/",
    },
    {
      img: p3,
      link: "https://www.jvc.com/in/",
    },
    {
      img: p4,
      link: "https://international.kef.com/",
    },
    {
      img: p5,
      link: "https://www.klipsch.com/",
    },
    {
      img: p6,
      link: "https://2gig.com/",
    },
    {
      img: p7,
      link: "https://www.accessnetworks.com/",
    },
    {
      img: p8,
      link: "https://www.acurusav.com/",
    },
    {
      img: p9,
      link: "https://international.alarm.com/adc/",
    },
    {
      img: p10,
      link: "https://www.apple.com/in/",
    },
    {
      img: p11,
      link: "https://www.aprilaire.com/",
    },
    {
      img: p12,
      link: "https://araknisnetworks.com/",
    },
    {
      img: p13,
      link: "https://www.aifittings.com/",
    },
    {
      img: p14,
      link: "https://atlona.com/",
    },
    {
      img: p15,
      link: "https://autonomic.com/",
    },
    {
      img: p16,
      link: "https://avproedge.com/?srsltid=AfmBOopUS1eGnftgUGu2p_hLJ33iem-Dl3Fx7GEzPvciQ14wzp6k1jcu",
    },
    {
      img: p17,
      link: "https://www.benq.com/en-in/index.html",
    },
    {
      img: p18,
      link: "https://www.binaryindia.com/",
    },
    {
      img: p19,
      link: "https://chief.com/",
    },
    {
      img: p20,
      link: "https://www.clarashades.com/",
    },
    {
      img: p21,
      link: "https://www.clarecontrols.com/clareone",
    },
    {
      img: p22,
      link: "https://www.clarecontrols.com/surveillance",
    },
    {
      img: p23,
      link: "https://cleerline.com/",
    },
    {
      img: p24,
      link: "https://www.control4.com/",
    },
    {
      img: p25,
      link: "https://www.coolcomponents.com/",
    },
    {
      img: p26,
      link: "https://www.definitivetechnology.com/en-us/",
    },
    {
      img: p27,
      link: "https://www.doorbird.com/",
    },
    {
      img: p28,
      link: "https://www.wearedragonfly.co/",
    },
    {
      img: p29,
      link: "https://www.ecobee.com/en-us/",
    },
    {
      img: p30,
      link: "https://episodespeakers.com/",
    },
    {
      img: p31,
      link: "https://episodespeakers.com/",
    },
  ];

  const nav = useNavigate();
  return (
    <div className="about-section">
      <div className="about-black">ABOUT US</div>
      <div
        className="about-black-below"
        style={{
          margin: "auto",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        <p>Discover the possibilities of home automation</p>
        <h3>Introduce efficiencies into your home with confidence</h3>
        <p>Create inviting spaces for everyone and bring happiness into your home.</p>
      </div>
      <div className="side-by-side">
        <div className="side-by-side-left">
          <img src={sbs1} alt="" />
        </div>
        <div className="side-by-side-right">
          {/* <p style={{ color: "#282866", fontSize: "0.9rem" }}>
            Company Background
          </p> */}
          <h3>Our Mission</h3>
          <p style={{ color: "rgba(0, 0, 0, 0.75)" }}>
            We are committed to creating smart, elegant, and innovative
            solutions that enrich the lives of our customers. Drawing
            inspiration from the ever-evolving landscape of technology, we have
            dedicated ourselves to pushing the limits of what's possible,
            pioneering innovative solutions that make everyday living truly
            exceptional. Our Smart and elegant solutions simplify your daily
            routines and provide the best entertainment, comfort, and security
            in any home space, creating an ideal environment for building
            long-lasting memories.
          </p>
          {/* <p style={{ fontSize: "1rem" }}>Sharon Gunther</p>
          <p style={{ color: "#AD9A9A", fontSize: "0.95rem" }}>Fresh Design</p> */}
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="side-by-side">
        <div className="side-by-side-right">
          {/* <p style={{ color: "#282866", fontSize: "0.9rem" }}>Our Purpose</p> */}
          <h3>Our Objectives</h3>
          <div className="d-flex align-items-start my-3">
            <div>
              <img src={tick} alt="" height={25} />
            </div>
            <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
              We strive to develop intelligent, uncomplicated, and streamlined
              solutions for your household. Our team will thoroughly assess your
              needs and challenges during the discovery process and create
              designs to enhance your experience.
            </div>
          </div>
          <div className="d-flex align-items-start my-3">
            <div>
              <img src={tick} alt="" height={25} />
            </div>
            <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
              With over 15 years of experience in the field, our team rigorously
              tests all high-quality smart products on the market to ensure that
              we deliver smart, connected, and elegant solutions. Our solutions
              work seamlessly, allowing customers to easily operate and depend
              on them consistently.
            </div>
          </div>
          <div className="d-flex align-items-start my-3">
            <div>
              <img src={tick} alt="" height={25} />
            </div>
            <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
              We are customer-focused and committed to surpassing your goals. We
              actively listen to your needs, goals, and challenges and work
              closely with your family to bring your dreams to fruition.
              Witnessing your vision come to life is the ultimate reward for us.
            </div>
          </div>
        </div>
        <div className="side-by-side-left">
          <img src={sbs2} alt="" />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="side-by-side">
        <div className="side-by-side-left-o">
          <img src={sbs3} alt="" />
        </div>
        <div className="side-by-side-right">
          {/* <p style={{ color: "#282866", fontSize: "0.9rem" }}>Our Approach</p> */}
          <h3>Our Steps</h3>
          <div className="d-flex align-items-start my-3">
            <div>
              <img src={tick} alt="" height={25} />
            </div>
            <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
              Our <b>discovery</b> process is not just about getting to know you, but
              about understanding your unique needs. We introduce our company
              and its members, and explain our business approach. This session,
              which may include a product demo, ensures we leave with a clear
              understanding of your requirements, ready to implement the right
              system for you.
            </div>
          </div>
          <div className="d-flex align-items-start my-3">
            <div>
              <img src={tick} alt="" height={25} />
            </div>
            <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
              In the <b>design</b> phase, we don't just put together ideas, products,
              and designs. We collaborate with you, sharing our proposals and
              seeking your feedback. This unique approach allows us to finalize
              a solution that meets your needs and expectations.
            </div>
          </div>
          <div className="d-flex align-items-start my-3">
            <div>
              <img src={tick} alt="" height={25} />
            </div>
            <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
              The <b>installation</b> process is exciting. The team arrives with all
              your equipment and devices pre-tested and configured and will work
              to assemble everything. Once the setup is up and running, the
              quality check will be completed before the solution is shared with
              you. The team will then schedule your three check-in sessions to
              educate you on any part of the system, answer any questions, and
              address any issues.
            </div>
          </div>
          <div className="d-flex align-items-start my-3">
            <div>
              <img src={tick} alt="" height={25} />
            </div>
            <div className="mx-3 mt-1" style={{ color: "rgba(0, 0, 0, 0.75)" }}>
              Our <b> support and maintenance team</b> offers plans to cover your investment and
              ensure periodic system reviews for software upgrades, tuning, and
              issues. Our comprehensive plans include full remote support, so
              you don't have to wait for someone to arrive.
            </div>
          </div>
          <button className="wwd-btn" onClick={() => nav("/consultation")}>
            REQUEST A QUOTE
          </button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="">
        <p style={{ textAlign: "center", color: "#757575" }}></p>
        <h3 style={{ textAlign: "center" }}>Brand Partners</h3>
        <br />
        <div className="parts">
          {logos.map((e, i) => (
            <Link key={i} to={e.link} target="_blank">
              <div className="part-each">
                <img src={e.img} height={30} alt="" />
              </div>
            </Link>
          ))}
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="about-signup">
        <h3 style={{ textAlign: "center" }}>Sign up to receive product</h3>
        <h3 style={{ textAlign: "center", marginTop: "-5px" }}>
          update or discounts
        </h3>
        <br />
        <div className="form-about-su">
          <div className="flname">
            <input
              className="flname-text"
              type="text"
              placeholder="First Name"
            />
            <input
              className="flname-text"
              type="text"
              placeholder="Last Name"
            />
          </div>
          <input
            className="flname-text my-4"
            style={{ width: "100%" }}
            type="email"
            placeholder="Email Address"
          />
          <br />
          <button className="btn-send-ab-su" style={{ width: "100%" }}>
            Send
          </button>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default About;
